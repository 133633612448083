import ThemeBox from "./themeBox/ThemeBox";
import ProfileMenu from "./profile/ProfileMenu";
import WalletMenu from "./wallet/WalletMenu";
import classes from "./TopNavRightBox.module.scss";
import FullScreen from "./fullScreen/FullScreen";
import { useDevice } from "../../../../store/deviceContext";

function TopNavRightBox() {
  const { isDesktop } = useDevice();
  return (
    <div className={classes.topNavBox_right}>
      {/* For mobile responsive code    style={(isMobile && isPortrait) ? {justifyContent: 'start'} : {justifyContent: 'flex-end'}} */}
      <div className={classes.wrapper} style={{justifyContent: 'flex-end'}}>
        <WalletMenu />
        <ThemeBox />
        {isDesktop && <FullScreen />}
      </div>
      {/* {(isDesktop || isIPad || (isMobile && isLandscape))&&
        <>
          <ThemeBox />
          <FullScreen />
        </>
      } */}
      <ProfileMenu />
    </div>
  );
}
export default TopNavRightBox;