import config from "../constants/config";
import moment from "moment";
import { MarketStatus } from "./enums";

export function isNonEmptyArr<T>(array: T[]): boolean {
  return Array.isArray(array) && array.length !== 0;
}

export const shouldShowError = (error: string | undefined,
  touched: boolean | undefined) => {
  return error && touched
}

export const getApiUrlEndpointAsPerRole = () => {
  let apiEndpoint: any = {};
  if (process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
    apiEndpoint = config.roleBasedConfig['owner'].apiEndpoint;
  } else {
    apiEndpoint = config.roleBasedConfig['whitelabel'].apiEndpoint;
  }
  return apiEndpoint;
}

export const formatDate = (date: Date | null) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const setLocalStorageData = (data: any) => {
  localStorage.setItem('token', data.accessToken);
  localStorage.setItem('user', data.userName);
  localStorage.setItem('loggedInUserID', data.id);
  if (data.refreshToken) {
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('role', data.role);
    localStorage.setItem('wlType', data.wlType);
    localStorage.setItem('PRole', data.parentRole);
    data.parentId && localStorage.setItem('parenId', data.parentId);
    data.parentName && localStorage.setItem('pName', data.parentName);
  }else {
    localStorage.setItem('role', data.role.name);
  }
}

export const removeLocalStorageData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('role');
  localStorage.removeItem('PRole');
  localStorage.removeItem('wlType');
  localStorage.removeItem('loggedInUserID');
  localStorage.removeItem('isLogin');
  localStorage.removeItem('parenId');
  localStorage.removeItem('pName');
}

export const formatIndianPayment = (number: string | null) => {
  let noConvertDecimalPt = Math.round((Number(number) + Number.EPSILON) * 100) / 100;
  let numStr = String(noConvertDecimalPt);
  numStr = numStr.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
  return numStr;
};

export const apiFailureRedirectHome = () => {
  localStorage.setItem('isLogin', JSON.stringify(false));
  removeLocalStorageData();//Remove local storgae data and redirect to login page
  window.location.href = "/login"
}

export const toggleFullScreen = () => {
  if ((document as any).fullscreenElement || (document as any).webkitFullscreenElement || (document as any).mozFullScreenElement || (document as any).msFullscreenElement) {
    // Exit fullscreen
    const element: any = document;
    if (element.exitFullscreen) {
      element.exitFullscreen();
    } else if (element.mozCancelFullScreen) { // Firefox
      element.mozCancelFullScreen();
    } else if (element.webkitExitFullscreen) { // Chrome, Safari and Opera
      element.webkitExitFullscreen();
    } else if (element.msExitFullscreen) { // IE/Edge
      element.msExitFullscreen();
    }
  } else {
    // Enter fullscreen
    // const element = document.documentElement as HTMLElement;
    const element: any = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    }else if (element.mozRequestFullScreen) { // Firefox
      element.mozRequestFullScreen();
    }  else if (element.msRequestFullscreen) { // IE/Edge
      element.msRequestFullscreen();
    }
  }
}

/** Return Market status text if its not open for showing an overlay */
export const getMarketOverlayText = (marketStatus: number | string)=>{
  if(typeof marketStatus === 'number') {
    switch(marketStatus) {
      case MarketStatus[0].id : return MarketStatus[0].text;
      case MarketStatus[1].id : return MarketStatus[1].text;
      case MarketStatus[2].id : return MarketStatus[2].text;
      case MarketStatus[3].id : return MarketStatus[3].text;
      case MarketStatus[4].id : return MarketStatus[4].text;
      default: return '';
    }
  }else {
    return marketStatus
    .split(' ')
    .map((word) => {
      if (word.toUpperCase() === "IN") {
        return "In";
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
  }
}

/** Handling PL data as per provided calculation using myShare & negative amount to positive value and vice-versa */
export const getPlDatausingMyShare = (plValue: number, myShare: number) => {
  let plAmt: number = 0;
  if(!myShare || myShare === 0) {
    plAmt = 0;//Number((plValue * -1).toFixed(2));  
  }else {
    plAmt = Number(((myShare / 100) * (plValue * -1)).toFixed(2));
  }
  return plAmt;
}