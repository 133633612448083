const images = {
  logo: require("../assets/images/project-logo.png"),
  logoMobile: require("../assets/images/project-logo-mobile.png"),
  avt: require("../assets/images/avatar.avif"),
  owner_avt: require("../assets/images/owner_avatar.avif"),
  notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),
  OtpVerificationn: require("../assets/images/otp-verification.png"),
  two_factor_auth_demo: require("../assets/images/2fa_verification.gif"),
  bg: require("../assets/images/bg.png"),
  pdf: require("../assets/images/pdf.png"),
  excel: require("../assets/images/excel.png"),
};

export default images;